<template>
  <div class="login">
    <div class="main">
      <swiper class="wrapper" :options="swiperOption">
        <swiper-slide class="swiper-item">
          <img class="swiper-img" src="../assets/fulin/01.jpg" alt />
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img" src="../assets/fulin/02.jpg" alt />
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img" src="../assets/fulin/03.jpg" alt />
        </swiper-slide>
      </swiper>
      <!--登录-->
      <div class="loginCentent" v-if="pageStatus == 1">
        <div class="centent-title">
          <div class="lg">
            <img class="syc-logo" :src="Logo_scy"  />
          </div>
          <div>
<!--           <h1 class="syc-monopoly">森林防火无人机视频图传平台</h1>
&lt;!&ndash;            <h2 style="margin-bottom:0.56rem">森林防火无人机视频图传平台</h2>&ndash;&gt;
            <h2 style="margin-bottom:0.56rem" />-->
          </div>
        </div>
<!--        <div class="syc-platform" v-if="$SYC">无人机管理平台</div>-->
        <Row type="flex" justify="center" class="lg">
          <Col span="16" class="lg">
            <Form
              ref="formInline"
              :model="formInline"
              :rules="ruleInline"
              id="loginForm"
            >
              <FormItem prop="user">
                <Row type="flex" justify="center" class="lg">
                  <Input
                    type="text"
                    style="background: #fff !important"
                    v-model.trim="formInline.user"
                  >
                    <Icon type="md-contact" slot="prefix" />
                  </Input>
                </Row>
              </FormItem>
              <FormItem prop="password" style="margin-top: 10px">
                <Row type="flex" justify="center" class="lg">
                  <Input
                    type="password"
                    v-model.trim="formInline.password"
                    @on-enter="login()"
                  >
                    <Icon type="md-unlock" slot="prefix" />
                  </Input>
                </Row>
              </FormItem>
              <FormItem>
                <Row type="flex" justify="space-around" class="lg" v-if="!$JL">
                  <!-- <CheckboxGroup v-model="formInline.checkbox">
                  <Checkbox label="记住密码"></Checkbox>
                </CheckboxGroup> -->
<!--                  <span
                    v-if="$public"
                    style="color: #29b8f8; cursor: pointer"
                    @click="pageStatus = 2"
                    >注册</span
                  >
                  <span
                    v-if="$public"
                    style="color: #aaa; cursor: pointer; marginleft: 10px"
                    @click="pageStatus = 4"
                    >忘记密码</span
                  >
                  <span
                    v-if="$public"
                    style="color: #aaa; cursor: pointer; marginleft: 10px"
                    @click="pageStatus = 5"
                    >短信登录</span
                  >-->
                </Row>
              </FormItem>
              <FormItem>
                <Row type="flex" justify="center" class="lg">
                  <input
                    type="button"
                    value="登录"
                    class="logIn"
                    @click="login()"
                  />
                </Row>
              </FormItem>
            </Form>
          </Col>
        </Row>
<!--        <div class="Powered">
          <h3>福安市林业局    联合飞行系统 V1.0.1</h3>
&lt;!&ndash;          <img src="../assets/img/dji@2x.png" alt="" />&ndash;&gt;
        </div>-->
      </div>
      <!--验证注册手机号码-->
      <div class="verifyCentent" v-if="pageStatus == 2">
        <div class="title">
          <h1>绑定手机号</h1>
          <h2>Binding Phone</h2>
        </div>
        <Row type="flex" justify="center" class="lg" class-name="verifyBox">
          <Col span="16" class="lg">
            <Form
              ref="verifyForm"
              :model="verifyForm"
              :rules="verifyRule"
              class="verifyForm"
            >
              <FormItem prop="phone">
                <Input
                  type="text"
                  v-model="verifyForm.phone"
                  placeholder="请输入手机号"
                  style="width: 2.5rem"
                ></Input>
              </FormItem>
              <FormItem prop="smscode">
                <div class="securityBox" style="margin-top: 0.16rem">
                  <Input
                    type="text"
                    v-model="verifyForm.smscode"
                    placeholder="短信验证码"
                    style="width: 2.5rem"
                  ></Input>
                  <div
                    class="security"
                    v-if="!reckon_time_shou"
                    @click="sendSecurity"
                  >
                    发送验证码
                  </div>
                  <div
                    class="security"
                    :class="reckon_time_ent ? 'bgc' : ''"
                    v-if="reckon_time_shou"
                    @click="sendSecurity"
                  >
                    重新发送
                    <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
                  </div>
                </div>
              </FormItem>
              <FormItem>
                <Row type="flex" justify="center" class="lg">
                  <input
                    type="button"
                    value="下一步"
                    class="logIn"
                    @click="nextStep()"
                  />
                </Row>
                <Col style="text-align: center; margin-top: 16px">
                  <span
                    style="color: #999999; cursor: pointer"
                    @click="pageStatus = 1"
                    >立即登录</span
                  >
                </Col>
              </FormItem>
            </Form>
          </Col>
        </Row>

        <div class="Powered">
          <h3>Powered by</h3>
          <img src="../assets/img/dji@2x.png" alt="" />
        </div>
      </div>
      <!--填写信息-->
      <div class="infoCentent" v-if="pageStatus == 3">
        <div class="title">
          <h1>注册</h1>
          <h2>Register</h2>
        </div>
        <Row type="flex" justify="center" class="lg" class-name="infoBox">
          <Form
            ref="infoForm"
            :model="infoForm"
            :rules="infoRule"
            :label-width="100"
            id="infoForm"
          >
            <FormItem prop="name" label="用户名">
              <Input v-model="infoForm.name" placeholder="请填写用户名"></Input>
            </FormItem>
            <FormItem prop="account" label="管理员账号">
              <Input
                v-model="infoForm.account"
                placeholder="请填写管理员账号"
              ></Input>
            </FormItem>
            <FormItem prop="fs_account" label="飞手账号">
              <Input
                v-model="infoForm.fs_account"
                placeholder="请填写飞手账号"
              ></Input>
            </FormItem>
            <FormItem prop="password" label="密码">
              <Input
                v-model="infoForm.password"
                type="password"
                autocomplete="new-password"
                placeholder="请填写密码"
              ></Input>
            </FormItem>
            <FormItem prop="password2" label="确认密码">
              <Input
                v-model="infoForm.password2"
                autocomplete="new-password"
                type="password"
                placeholder="再次输入密码"
              ></Input>
            </FormItem>
            <FormItem>
              <Row type="flex" justify="center" class="lg">
                <input
                  type="button"
                  value="上一步"
                  class="Last_step"
                  @click="pageStatus = 2"
                />
                <input
                  type="button"
                  value="完成注册"
                  class="register"
                  @click="register()"
                />
              </Row>
              <Col style="text-align: center; margin-top: 16px">
                <span
                  style="color: #999999; cursor: pointer"
                  @click="pageStatus = 1"
                  >立即登录</span
                >
              </Col>
            </FormItem>
          </Form>
        </Row>
        <div class="Powered">
          <h3 style="">Powered by</h3>
          <img src="../assets/img/dji@2x.png" alt="" />
        </div>
      </div>
      <!--找回密码-->
      <div class="verifyCentent" v-if="pageStatus == 4">
        <div class="title">
          <h1>找回密码</h1>
          <h2>forgotPwd</h2>
        </div>

        <Form
          ref="forgotPwd"
          :model="forgotPwd"
          :rules="forgotRule"
          :label-width="80"
          class="verifyForm"
          label-position="left"
        >
          <FormItem prop="phone" label="手机号">
            <Input
              type="text"
              v-model="forgotPwd.phone"
              placeholder="请输入手机号"
              style="width: 2.5rem"
            ></Input>
          </FormItem>
          <FormItem prop="password" label="密码">
            <Input
              v-model="forgotPwd.password"
              type="password"
              autocomplete="new-password"
              placeholder="请填写密码"
              style="width: 2.5rem"
            ></Input>
          </FormItem>
          <FormItem prop="password2" label="确认密码">
            <Input
              v-model="forgotPwd.password2"
              autocomplete="new-password"
              type="password"
              placeholder="再次输入密码"
              style="width: 2.5rem"
            ></Input>
          </FormItem>
          <FormItem prop="smscode" label="验证码">
            <div class="securityBox">
              <Input
                type="text"
                v-model="forgotPwd.smscode"
                placeholder="短信验证码"
                style="width: 2.5rem"
              ></Input>
              <div
                class="security"
                v-if="!reckon_time_shou"
                @click="sendSecurityForget"
              >
                发送验证码
              </div>
              <div
                class="security"
                :class="reckon_time_ent ? 'bgc' : ''"
                v-if="reckon_time_shou"
                @click="sendSecurityForget"
              >
                重新发送
                <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
              </div>
            </div>
          </FormItem>

          <Row type="flex" justify="center" class="lg" style="padding-top: 5px">
            <input
              type="button"
              value="完成修改"
              class="logIn"
              @click="retrievePassword()"
            />
          </Row>
          <Col style="text-align: center; margin-top: 5px">
            <span
              style="color: #999999; cursor: pointer"
              @click="pageStatus = 1"
              >返回登录</span
            >
          </Col>
        </Form>

        <div class="Powered">
          <h3 style="">Powered by</h3>
          <img src="../assets/img/dji@2x.png" alt="" />
        </div>
      </div>
      <!--手机号码登录-->
      <div class="verifyCentent" v-if="pageStatus == 5">
        <div class="title">
          <h1>手机号登录</h1>
          <h2>Login by Phone</h2>
        </div>
        <Row type="flex" justify="center" class="lg" class-name="verifyBox">
          <Form
            ref="verifyForm"
            :model="verifyForm"
            :rules="verifyRule"
            class="verifyForm"
          >
            <FormItem prop="phone">
              <Input
                type="text"
                v-model="verifyForm.phone"
                placeholder="请输入手机号"
                style="width: 3.5rem"
              ></Input>
            </FormItem>
            <FormItem prop="smscode">
              <div class="securityBox" style="margintop: 16px">
                <Input
                  type="text"
                  v-model="verifyForm.smscode"
                  placeholder="短信验证码"
                  style="width: 3.5rem"
                ></Input>
                <div
                  class="security"
                  v-if="!reckon_time_shou"
                  @click="sendSecurity"
                >
                  发送验证码
                </div>
                <div
                  class="security"
                  :class="reckon_time_ent ? 'bgc' : ''"
                  v-if="reckon_time_shou"
                  @click="sendSecurity"
                >
                  重新发送
                  <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
                </div>
              </div>
            </FormItem>
            <FormItem>
              <Row type="flex" justify="center" class="lg">
                <input
                  type="button"
                  value="验证登录"
                  class="logIn"
                  @click="phoneLogin()"
                />
              </Row>
              <Col style="text-align: center; margin-top: 16px">
                <span
                  style="color: #999999; cursor: pointer"
                  @click="pageStatus = 1"
                  >账号登录</span
                >
              </Col>
            </FormItem>
          </Form>
        </Row>
        <div class="Powered">
          <h3>Powered by</h3>
          <img src="../assets/img/dji@2x.png" alt="" />
        </div>
      </div>
    </div>
<!--    <div class="official-website" v-if="!$JL">
      <a href="https://jf.jointflight.net" target="_blank">
        访问大狗科技官网
      </a>
    </div>-->
    <div class="foo2" v-if="$public&&!$JL">
      <div class="tiaoli">
        <p class="tl-link">
          技术支持：&nbsp; 大狗科技&福莱航空   &nbsp;&nbsp;&nbsp;
<!--          <a href="https://jf.jointflight.net" target="_blank">湖南大狗科技有限公司</a>-->
          版权号：&nbsp;联合飞行系统 V1.0.1
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import '../assets/font/font.css'
import Api from '../utils/api.js'
// import axios from 'axios';
export default {
  name: 'login',

  data: function () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.infoForm.password2 !== '') {
          // 对第二个密码框单独验证
          this.$refs.infoForm.validateField('password2')
        }
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.infoForm.password) {
        callback(new Error('两个输入密码不匹配!'))
      } else {
        callback()
      }
    }
    const forgotPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.forgotPwd.password2 !== '') {
          // 对第二个密码框单独验证
          this.$refs.forgotPwd.validateField('password2')
        }
        callback()
      }
    }
    const forgotPassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.forgotPwd.password) {
        callback(new Error('两个输入密码不匹配!'))
      } else {
        callback()
      }
    }
    return {
      Logo_scy: require('@/assets/fulin/login-img.png'),
      pageStatus: 1, //1.登录2.注册->手机验证3.信息填写
      swiperOption: {
        //循环
        loop: true,
        //设定初始化时slide的索引
        initialSlide: 0,
        //自动播放
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //滑动速度
        speed: 1500,
        //滑动方向
        direction: 'horizontal',
        //小手掌抓取滑动
        grabCursor: true,
      },
      // 登录表单
      formInline: {
        user: '',
        password: '',
        checkbox: ['记住密码'],
      },
      ruleInline: {
        user: [
          { required: true, message: '请填写账号', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            max: 12,
            message: '账号长度 6 - 12个字符',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 20, message: '密码长度 8 - 20个字符' },
        ],
      },
      reckon_time: 60,
      reckon_time_shou: false,
      reckon_time_ent: true,
      // 获取验证码表单
      verifyForm: {
        phone: '',
        smscode: '',
      },
      verifyRule: {
        phone: [
          { required: true, message: '请填写注册手机' },
          {
            type: 'string',
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请输入正确的手机号码',
          },
        ],
        smscode: [
          { required: true, message: '请输入验证码' },
          {
            type: 'string',
            pattern: /^[0-9]{6}$/,
            message: '请输入正确的验证码',
          },
        ],
      },
      reckon_time_timer: null,
      registerPhone: '', //注册的手机号
      infoForm: {
        name: '',
        password: '',
        password2: '',
        fs_account: '',
        account: '',
      },
      // 注册表单
      infoRule: {
        name: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          { min: 4, max: 12, message: '用户名长度 4 - 12个字符' },
        ],
        password: [
          {
            required: true,
            message: '请填写密码',
            validator: validatePass,
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 8,
            max: 20,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为8-20个字符的字母与数字',
          },
        ],
        password2: [
          { required: true, validator: validatePassCheck, trigger: 'blur' },
        ],
        fs_account: [
          { required: true, message: '请填写飞手账号', trigger: 'blur' },
          { min: 6, max: 12, message: '飞手账号长度 6 - 12个字符' },
        ],
        account: [
          { required: true, message: '请填写管理员账号', trigger: 'blur' },
          { min: 6, max: 12, message: '管理员账号长度 6 - 12个字符' },
        ],
      },
      // 找回密码表单
      forgotPwd: {
        phone: '',
        password: '',
        password2: '',
        smscode: '',
      },
      forgotRule: {
        phone: [
          { required: true, message: '请填写注册手机', trigger: 'blur' },
          {
            type: 'string',
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请输入正确的手机号码',
          },
        ],
        password: [
          {
            required: true,
            message: '请填写密码',
            validator: forgotPass,
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 8,
            max: 20,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为8-20个字符的字母与数字',
          },
        ],
        password2: [
          { required: true, validator: forgotPassCheck, trigger: 'blur' },
        ],
        smscode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            type: 'string',
            pattern: /^[0-9]{6}$/,
            message: '请输入正确的验证码',
          },
        ],
      },
      loginBtnLoading: false,
    }
  },

  methods: {
    login() {
      sessionStorage.clear()
      let _this = this
      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          //   _this.loginBtnLoading = true;
         /* console.log(Api.Login())*/
          _this.$post(Api.Login(), {
              account: _this.formInline.user,
              //   password: _this.formInline.password,
              password: this.$publicdata.encrypt(_this.formInline.password),
              client: 'web',
            }).then((res) => {
              if (res.code == 0) {
                _this.$Notice.error({
                  title: '登录提示',
                  desc: res.msg_customer,
                })
              } else {
                // 储存数据
                // if (_this.$public && _this.$online) {
                //   _this
                //     .$post(Api.getUserSig(), {
                //       user_id: res.data.user_info.user_id.toString(),
                //     })
                //     .then((res) => {
                //       if (res.code == 1) {
                //         // 语音通话登录>>>>>
                //         that.$trtcCalling.login({
                //           userID: res.data.user_info.user_id.toString(),
                //           userSig: res.data.userSig,
                //         });
                //       // <<<<<语音通话
                //       } else {
                //         that.$Message.error(res.msg_customer);
                //       }
                //     });
                // }

                // sessionStorage.setItem(
                //   'user_info',
                //   JSON.stringify(res.data.user_info)
                // );
                // let token = '?token=' + res.data.token;
                let token = res.data.token
                sessionStorage.setItem('token', token)
                sessionStorage.setItem('isEnable', 1)
                sessionStorage.setItem(
                  'user_info',
                  JSON.stringify(res.data.user_info)
                )

                localStorage.setItem('account', _this.formInline.user)
                localStorage.setItem('password', _this.formInline.password)

                if (res.data.user_info.is_manager === 0) {
                  sessionStorage.setItem('userType', 2) // 飞手
                } else {
                  sessionStorage.setItem('userType', 1) // 管理员
                }
                sessionStorage.setItem(
                  'userPhone',
                  res.data.user_info.mobile_phone
                )


                  if(this.$JL){
                    _this.$router.push({
                      path: '/JilinProvince',
                    })
                   this.$nextTick(()=>{
                     var de = document.documentElement;
                     if (de.requestFullscreen) {
                       de.requestFullscreen();
                     } else if (de.mozRequestFullScreen) {
                       de.mozRequestFullScreen();
                     } else if (de.webkitRequestFullScreen) {
                       de.webkitRequestFullScreen();
                     } else if (de.msRequestFullscreen) {
                       de.msRequestFullscreen();
                     }
                   })
                  }else {
                    // 跳转页面
                    if (typeof res.data.user_info.name != 'undefined') {
                      _this.$router.push({
                        path: '/live/watch-lists',
                      })
                    }
                  }

                 //shangyu
                if(res.data.user_info.response_entity.body.code === 0){
                  let sydata=res.data.user_info.response_entity.body.data
                  sessionStorage.setItem('sy_token', sydata.access_token)
                  sessionStorage.setItem('sy-user_id', sydata.user_id)
                  sessionStorage.setItem('sy-workspace_id',sydata.workspace_id)
                  sessionStorage.setItem('sy_muen','true')
                }else  if(res.data.user_info.response_entity.body.code === 401){
                  sessionStorage.setItem('sy_muen','false')
                }



                // 记住密码
                if (_this.formInline.checkbox.length > 0) {
                  sessionStorage.account = _this.formInline.user
                  sessionStorage.pwd = _this.formInline.password
                }
              }
              _this.loginBtnLoading = false
            })
            .catch(function (error) {
              _this.loginBtnLoading = false
            })
        } else {
          this.$Message.error('请正确填写用户名和密码')
          _this.loginBtnLoading = false
        }
      })


    },
    // 手机号短信登录
    phoneLogin() {
      sessionStorage.clear()
      var _this = this
      this.$refs['verifyForm'].validate((valid) => {
        if (valid) {
          _this
            .$post(Api.Login(), {
              mobile_phone: _this.verifyForm.phone,
              code: _this.verifyForm.smscode,
              client: 'web',
            })
            .then((res) => {
              if (res.code == 0) {
                _this.$Notice.error({
                  title: '登录提示',
                  desc: res.msg_customer,
                })
              } else {
                // 储存数据
                sessionStorage.setItem(
                  'user_info',
                  JSON.stringify(res.data.user_info)
                )
                // let token = '?token=' + res.data.token;
                let token = res.data.token
                sessionStorage.setItem('token', token)
                sessionStorage.setItem(
                  'user_info',
                  JSON.stringify(res.data.user_info)
                )
                if (res.data.user_info.is_manager === 0) {
                  sessionStorage.setItem('userType', 2) // 飞手
                } else {
                  sessionStorage.setItem('userType', 1) // 管理员
                }
                sessionStorage.setItem(
                  'userPhone',
                  res.data.user_info.mobile_phone
                )
                // 跳转页面
                if (typeof res.data.user_info.name != 'undefined') {
                  _this.$router.push({
                    path: '/live/watch-lists',
                  })
                }
              }
            })
        } else {
          this.$Message.error('请正确填写用户名和密码')
        }
      })
    },
    // 发送验证码
    sendSecurity() {
      if (this.reckon_time_shou && this.reckon_time_ent) {
        return
      }
      this.$refs.verifyForm.validateField('phone', (msg) => {
        if (msg !== '') {
          return
        } else {
          this.$post(Api.sendCloudSms(), {
            phone: this.verifyForm.phone,
          }).then((res) => {
            console.log(res)
            if (res.code == 1) {
              if (res.msg_customer == '此号码已被注册') {
                this.$Message.info(res.msg_customer)
                return
              }
              this.$Message.info(res.msg_customer)
              this.reckon_time_shou = true
              this.reckon_time_ent = true
              this.reckon_time_timer = setInterval(() => {
                if (this.reckon_time == 0) {
                  clearInterval(this.reckon_time_timer)
                  this.reckon_time = 60
                  this.reckon_time_ent = false
                }
                this.reckon_time = this.reckon_time - 1
              }, 1000)
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        }
      })
    },
    // 发送验证码-忘记密码
    sendSecurityForget() {
      if (this.reckon_time_shou && this.reckon_time_ent) {
        console.log('time_end', this.reckon_time_shou)
        console.log('time_end', this.reckon_time_ent)
        return
      }
      this.$refs.forgotPwd.validateField('phone', (msg) => {
        if (msg !== '') {
          return
        } else {
          this.$post(Api.sendCloudSms(), {
            phone: this.forgotPwd.phone,
          }).then((res) => {
            console.log(res)
            if (res.code == 1) {
              if (res.msg_customer == '此号码已被注册') {
                this.$Message.info(res.msg_customer)
                return
              }
              this.$Message.info(res.msg_customer)
              this.reckon_time_shou = true
              this.reckon_time_ent = true
              this.reckon_time_timer = setInterval(() => {
                if (this.reckon_time == 0) {
                  clearInterval(this.reckon_time_timer)
                  this.reckon_time = 60
                  this.reckon_time_ent = false
                }
                this.reckon_time = this.reckon_time - 1
              }, 1000)
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        }
      })
    },
    //下一步
    nextStep() {
      var _this = this
      this.$refs['verifyForm'].validate((valid) => {
        if (valid) {
          _this
            .$post(Api.checkSms(), {
              phone: this.verifyForm.phone,
              code: this.verifyForm.smscode,
            })
            .then((res) => {
              console.log(res)
              if (res.code == 1) {
                this.registerPhone = this.verifyForm.phone
                this.pageStatus = 3
                this.$refs['verifyForm'].resetFields()
                this.infoForm.account = this.registerPhone
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
        } else {
          this.$Message.error('请正确填写用户名和密码')
        }
      })
    },

    //注册
    register() {
      var _this = this
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          _this
            .$post(Api.trialTeamRegister(), {
              account: this.infoForm.account,
              fs_account: this.infoForm.fs_account,
              name: this.infoForm.name,
              team_name: this.infoForm.name,
              //   location: JSON.stringify(this.IPlocation),
              mobile_phone: this.registerPhone,
              //   password: this.infoForm.password,
              password: this.$publicdata.encrypt(this.infoForm.password),
            })
            .then((res) => {
              console.log(res)
              if (res.code == 1) {
                this.pageStatus = 1
                this.$Message.info(res.msg_customer)
                this.$refs['infoForm'].resetFields()
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
        } else {
          this.$Message.error('请正确填写用户名和密码')
        }
      })
    },
    // 找回密码
    retrievePassword() {
      var _this = this
      this.$refs['forgotPwd'].validate((valid) => {
        if (valid) {
          _this
            .$post(Api.retrievePassword(), {
              code: this.forgotPwd.smscode,
              mobile_phone: this.forgotPwd.phone,
              //   password: this.forgotPwd.password,
              password: this.$publicdata.encrypt(this.forgotPwd.password),
            })
            .then((res) => {
              console.log(res)
              if (res.code == 1) {
                this.pageStatus = 1
                this.$Message.info(res.msg_customer)
                this.$refs['forgotPwd'].resetFields()
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
        } else {
          this.$Message.error('请正确填写用户名和密码')
        }
      })
    },
  },

  mounted() {
    // var _this = this;

    // 记住密码
    if (typeof sessionStorage.account != 'undefined') {
      this.formInline.user = sessionStorage.account
      this.formInline.password = sessionStorage.pwd
    }
  },
}
</script>

<style scoped lang="scss">
.official-website {
  position: fixed;
  text-align: center;
  bottom: 20px;
  width: 100%;
  a {
    color: #707070;
    font-size: 16px;
  }
}
.foo2 {
  position: fixed;
  bottom: 10px;
  width: 50%;
  margin:0 25%;
  text-align: center;
  padding-left: 20px;
  .tiaoli {
    a {
      color: #707070;
    }
    a:hover{
      color: #2d8cf0;
    }
  }
}
.main {
  height: 7.27rem;
  min-height: 460px;
  min-width: 740px;
  width: 12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  .wrapper {
    width: 7rem;
    height: 7.27rem;
    min-height: 460px;
    min-width: 400px;
    margin: 0;
    .swiper-item {
      width: 7.2rem;
      height: 7.48rem;
      min-height: 460px;
      overflow: hidden;
      img {
        width: 100%;
        min-height: 460px;
      }
    }
  }
  .loginCentent {
    padding-top: 0.4rem;
    position: relative;
    width: 5rem;
    min-width: 340px;
    .centent-title {
/*      display: flex;
      justify-content: center;
      align-items: center;*/
      text-align: center;
      >div.lg{
        img{
          margin: 10px auto;
          margin-bottom: 30px;
        }
      }
    }
    .syc-platform {
      color: #333;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      margin-top: 0.4rem;
      margin-bottom: 0.56rem;
    }
    h1 {
      font-size: 0.28rem;
      color: #333;
      text-align: center;
      font-weight: bold;
      margin-bottom: 6px;
      font-family: 'SY';
    }

    h2 {
      font-size: 0.24rem;
      color: #757172;
      text-align: center;
      font-weight: bold;
    }
    .syc-logo {
      width: 100%;
     /* width: 500px;
      height: 300px;*/
      margin-right: 10px;
    }
    .syc-monopoly {
      font-size: 19px;
      font-weight: bold;
      letter-spacing: 10.7px;
     /* text-align: left;*/
    }
    .syc-monopoly-jl {
      font-size: 19px;
      font-weight: bold;
      letter-spacing: 2px;
      text-align: left;
    }
    .syc-company {
      font-size: 19px;
      font-weight: bold;
      text-align: left;
    }

    #loginForm {
      .icomBox {
        width: 100%;
        height: 100%;
        border-bottom: solid 1px #707070;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0.16rem;
          height: auto;
        }
      }
      .logIn {
        width: 2.74rem;
        height: 0.36rem;
        line-height: 0.36rem;
        background-color: #333333;
        font-size: 0.2rem;
        color: #fff;
        cursor: pointer;
        border: none;
      }
    }
    /deep/ .ivu-input {
      background-color: #fff !important;
      color: #000 !important;
      border-bottom: solid 1px #707070 !important;
    }
  }

  #loginForm {
    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
      color: #000 !important;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }

  .verifyCentent {
    padding-top: 1rem;
    width: 5rem;
    position: relative;
    min-height: 460px;
    min-width: 300px;
    .title {
      margin: 0 auto 0.46rem;
      // margin-bottom: 0.46rem;
      h1 {
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
        margin-bottom: 4px;
      }

      h2 {
        color: #757172;
        font-size: 0.22rem;
        text-align: center;
      }
    }

    .verifyBox {
      .securityBox {
        position: relative;
        .security {
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 1.2rem;
          height: 0.28rem;
          background-color: #2196f3;
          font-size: 0.14rem;
          color: #fff;
          text-align: center;
          line-height: 0.28rem;

          span {
            font-size: 0.12rem;
          }
        }

        .bgc {
          background-color: #97ccf8;
        }
      }
    }
  }

  .verifyForm {
    padding: 0 0.5rem;
    .logIn {
      width: 2.3rem;
      height: 0.36rem;
      line-height: 0.36rem;
      background-color: #333333;
      font-size: 0.18rem;
      color: #fff;
      letter-spacing: 4px;
      cursor: pointer;
      border: none;
      margin-top: 7px;
    }

    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
      color: #000 !important;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }

  .infoCentent {
    padding-top: 0.5rem;
    width: 4.8rem;
    position: relative;

    .title {
      margin-bottom: 0.2rem;

      h1 {
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
        margin-bottom: 4px;
      }

      h2 {
        color: #757172;
        font-size: 0.22rem;
        text-align: center;
      }
    }
    .upload-list {
      display: inline-block;
      width: 1rem;
      height: 0.98rem;
      text-align: center;
      line-height: 0.98rem;
      border: 1px solid transparent;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      box-shadow: 0 3px 6px #eee;
      margin-right: 4px;
    }
    .upload-list img {
      width: 100%;
      height: 100%;
    }
    .upload-list-cover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .upload-list:hover .upload-list-cover {
      display: block;
    }
    .upload-list-cover i {
      color: #fff;
      font-size: 0.2rem;
      cursor: pointer;
      margin: 0 2px;
    }
  }

  #infoForm {
    .register {
      width: 2.05rem;
      height: 0.36rem;
      line-height: 0.36rem;
      background-color: #333333;
      font-size: 0.14rem;
      color: #fff;
      cursor: pointer;
      border: none;
    }
    .Last_step {
      margin-right: 0.13rem;
      width: 1rem;
      height: 0.36rem;
      background: #ffffff;
      border: 1px solid #707070;
      color: #333333;
      font-size: 0.14rem;
      border-radius: 0;
      outline: none;
      cursor: pointer;
    }
    .infoUpload {
      margin-bottom: 0.2rem;
      > span {
        font-size: 0.16rem;
        line-height: 0.58rem;
        margin-left: 0.1rem;
      }
    }

    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
      color: #000 !important;
      //   margin-top: 20px;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }
  /deep/ .ivu-form-item {
    margin-bottom: 26px;
  }
  .Powered {
    position: absolute;
    bottom: 0.1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    h3 {
      font-size: 0.16rem;
      margin-right: 6px;
    }

    img {
      width: 0.35rem;
      height: auto;
    }
  }
}
</style>
